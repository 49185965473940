.switch {
  --line: #b2cfe9;
  --dot: #3958ff;
  --circle: #3958ff;
  --duration: 0.3s;
  --text: #3958ff;
  cursor: pointer;
}
.switch input {
  display: none;
}
.switch input + div {
  position: relative;
}
.switch input + div:before,
.switch input + div:after {
  --s: 1;
  content: "";
  position: absolute;
  height: 8px;
  top: 9px;
  width: 24px;
  background: var(--line);
  transform: scaleX(var(--s));
  transition: transform var(--duration) ease;
}
.switch input + div:before {
  --s: 0;
  left: 0px;
  transform-origin: 0 50%;
  border-radius: 5px 5px 5px 5px;
}
.switch input + div:after {
  left: 20px;
  transform-origin: 100% 50%;
  border-radius: 5px 5px 5px 5px;
}
.switch input + div span {
  padding-left: 42px;
  line-height: 24px;
  color: var(--text);
}
.switch input + div span:before {
  --x: 0;
  --b: var(--dot);
  --s: 4px;
  content: "";
  background: var(--dot);
  position: absolute;
  left: 0;
  top: 5px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  box-shadow: inset 0 0 0 var(--s) var(--b);
  transform: translateX(var(--x));
  transition: box-shadow var(--duration) ease, transform var(--duration) ease;
}
.switch input:checked + div:before {
  --s: 1;
}
.switch input:checked + div:after {
  --s: 0;
}
.switch input:checked + div span:before {
  --x: 28px;
  --s: 12px;
  --b: var(--dot);
}
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
* {
  box-sizing: inherit;
}
*:before,
*:after {
  box-sizing: inherit;
}
